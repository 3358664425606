import { BrowserRouter } from "react-router-dom";
import Router from "./layouts/layout-provider";
import "./styles/App.css";

function App() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

export default App;
