import React from "react";
import styles from "../../styles/components/Wrapper/Wrapper.module.css";

export default function Wrapper({ children }) {
  return (
    <>
      <div className={styles["header"]}>
        <div>
          <img
            src="/flook-ai-logo.png"
            alt="Flook.Ai"
            height={55}
            width={55}
          />
        </div>

        <div className={styles["menu-items-container"]}>
            <div>Contact Us</div>
            <div className={styles["menu-install-button"]}>Install App</div>
        </div>
      </div>
      {children}
    </>
  );
}
