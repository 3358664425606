import Page404 from "../layouts/404";
import Home from "../layouts/Home";

const PageRoutes = [
  {
    path: "/",
    element: Home,
    title: "Home",
  },
  {
    path: "*",
    element: Page404,
    title: "404",
  },
];

export default PageRoutes;
