import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import Wrapper from "../components/Wrapper";

import RouterData from "../constants/routes";

export default function Router() {
  const pageRoutes = RouterData.map(({ path, title, element: ElementComp }) => {
    return (
      <Route
        key={title}
        path={`${path}`}
        element={<Wrapper>{<ElementComp />}</Wrapper>}
      />
    );
  });

  // pageRoutes.unshift(
  //   <Route key={"redirect"} path="/" element={<Navigate to="/" />} />
  // );

  return <Routes>{pageRoutes}</Routes>;
}
