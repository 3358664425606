import React from "react";
import styles from "../../styles/layouts/Home/Home.module.css";

export default function Home() {
  return (
    <>
      <div className={styles["hero-section"]}>
        <div className={styles["service-image-container"]}>
          <div>
            <img
              src="/img/star.png"
              alt="Shopify Reviews App"
              height={"200px"}
              width={"200px"}
            />
          </div>

          <div>
            <img
              src="/img/wallet.png"
              alt="Shopify Rewards and Loyalty App"
              height={"200px"}
              width={"200px"}
            />
          </div>
          <div>
            <img
              src="/img/affiliate-marketing.png"
              alt="Shopify Affiliate Marketing App"
              height={"200px"}
              width={"200px"}
            />
          </div>
        </div>

        <h1 className={styles["hero-title"]}>Meet your partner for growth.</h1>
        <p>
          Flook.ai is a Shopify app that helps you to collect customer feedback,
          make them speak about your brand and reward them for their loyalty.
        </p>

        {/* <h4 className={styles["rating-text"]}>Shopify Rating</h4> */}

        {/* <div className={styles["review-star-container"]}>
          <div className={styles["review-star"]}></div>
          <div className={styles["review-star"]}></div>
          <div className={styles["review-star"]}></div>
          <div className={styles["review-star"]}></div>
          <div className={styles["review-star"]}></div>
        </div> */}

        {/* <button>Install App</button> */}
      </div>

      {/* <div className={styles["partner-brand-section"]}>
        <h3>Trusted by 100+ Brands</h3> */}

      {/* <div className={styles["partner-brand-logo-container"]}>
          <img src="/img/brands/bvo_logo.jpeg" alt="Flook.Ai Shopify App" />
          <img src="/img/brands/bvo_logo.jpeg" alt="Flook.Ai Shopify App" />
          <img src="/img/brands/bvo_logo.jpeg" alt="Flook.Ai Shopify App" />
          <img src="/img/brands/bvo_logo.jpeg" alt="Flook.Ai Shopify App" />
          <img src="/img/brands/bvo_logo.jpeg" alt="Flook.Ai Shopify App" />
          <img src="/img/brands/bvo_logo.jpeg" alt="Flook.Ai Shopify App" />
          <img src="/img/brands/bvo_logo.jpeg" alt="Flook.Ai Shopify App" />
          <img src="/img/brands/bvo_logo.jpeg" alt="Flook.Ai Shopify App" />
          <img src="/img/brands/bvo_logo.jpeg" alt="Flook.Ai Shopify App" />
          <img src="/img/brands/bvo_logo.jpeg" alt="Flook.Ai Shopify App" />
          <img src="/img/brands/bvo_logo.jpeg" alt="Flook.Ai Shopify App" />
          <img src="/img/brands/bvo_logo.jpeg" alt="Flook.Ai Shopify App" />
          <img src="/img/brands/bvo_logo.jpeg" alt="Flook.Ai Shopify App" />
          <img src="/img/brands/bvo_logo.jpeg" alt="Flook.Ai Shopify App" />
        </div> */}
      {/* </div> */}

      <div className={styles["features-section"]}>
        <div>
          <h2>
            Brands have seen minimum 3X growth in repeat purchase after using
            our services.
          </h2>
        </div>

        <div>
          <img src="/img/growth.png" alt="Flook.Ai Shopify App" />
        </div>
      </div>

      {/* <div className={styles["video-section"]}>
          <div className={styles["video-section-container"]}>
              <div className={styles["header"]}>
                  <button>
                    Schedule Demo
                  </button>
              </div>
          </div>
      </div> */}

      <div className={styles["features-section-secondary"]}>
        <div>
          <img src="/img/product-quality.png" alt="Flook.Ai Shopify App" />
        </div>

        <div>
          <h2>
            Flook.Ai helps you to focus on improving product quality via
            continuous customer feedback.
          </h2>
        </div>
      </div>

      <footer className={styles["footer-section"]}>
        <p>message@flook.ai</p>
        <p>
          © {new Date().getFullYear()} - All Rights reserved to Flook
          Innovations Private Limited
        </p>
      </footer>
    </>
  );
}
